<template>
  <div>
    <h3>Deal Information</h3>
    <p v-if="!loading && viewable">
      Full address and application details will be provided upon interest in
      pursuing lending opporutnity.
    </p>
    <p v-if="loading">Loading...</p>
    <br />
    <div v-if="!loading && viewable">
      <Read_Deal_Summary
        @getDealInfo="getDealInfo"
        :company="company"
        :deal_summary_data="deal_summary_data"
        view_type="logged_in"
      />
    </div>

    <div v-if="!loading && !viewable">
     <v-alert type="warning">This lending opportunity is not currently available for viewing.</v-alert>
    </div>
  </div>
</template>

<script>

import API from "@/plugins/API";
import filters from "@/plugins/filters";

import Read_Deal_Summary from "@/components/Read_Deal_Summary";

export default {
  props: ["deal_summary_id"],
  components: {
    Read_Deal_Summary,
  },

  data() {
    return {
      deal_summary_data: {},
      my_offers: [],
      company: {},
      viewable: false,
      loading: true,
    };
  },
  methods: {
    async getDealInfo() {
      try {
        let dealResponse = await API().get(
          `/api/user_read_deal_summary/${this.deal_summary_id}/`
          // `/api/deals/${this.dealId}/?populate[0]=deal_summary&populate[1]=deal_offers`
        );
        // console.log(dealResponse);
     
        this.deal_summary_data = dealResponse.data;
        this.company = this.$store.getters["Auth/getAuthUser"].companies[0];

        this.deal_summary_data.summary_offers.forEach(offer => {
          if(offer.company.id == parseInt(this.company.id) ){
            offer.my_offer = true
          } else {
            offer.my_offer = false
          }
        });

        // iterate through existing offers and see if users company made the offer
        // this.my_offers = this.deal_summary_data.summary_offers.filter((offer)=>{
        //   if (offer.company.id == parseInt(this.company.id)){
        //     return offer
        //   }
        // })

     
      
        this.viewable = true;
        this.loading = false;
      } catch (error) {
        console.log(error)
        this.viewable = false;
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.getDealInfo();
  },
  filters: {
    ...filters,
  },
};
</script>

<style lang="scss" scoped>
tr:hover {
  background-color: transparent !important;
}
</style>